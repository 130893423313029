import { render, staticRenderFns } from "./Part1.vue?vue&type=template&id=e1a22ccc&scoped=true&"
import script from "./Part1.vue?vue&type=script&lang=js&"
export * from "./Part1.vue?vue&type=script&lang=js&"
import style0 from "./Part1.vue?vue&type=style&index=0&id=e1a22ccc&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e1a22ccc",
  null
  
)

export default component.exports