<template>
  <div class="Part4">
    <div class="w">
      <div class="titleBlack">
        <div>粮食一体化解决方案服务保障</div>
        <div class="tipBlack">提供高效率地的服务保障，促进企业更稳步的发展</div>
      </div>
      <!-- <img src="@/assets/image/solution/grain/part4@2x.png" alt="" /> -->
      <div class="box">
        <div class="one">
          <img src="@/assets/image/pic/weight.png" alt style="width:124px;height:124px">
          <p class="text">设备物联网保障</p>
          <p class="stext">以客户为核心，在粮食交易场景中利用高精设备精确管理重量</p>
        </div>
        <div class="one">
          <img src="@/assets/image/pic/pay.png" alt style="width:124px;height:124px">
          <p class="text">支付中心安全保障</p>
          <p class="stext">以客户为核心，在粮食交易场景中利用银联设备进行支付</p>
        </div>
        <div class="one">
          <img src="@/assets/image/pic/ensure.png" alt style="width:124px;height:124px">
          <p class="text">多基地管理保障</p>
          <p class="stext">以基地为核心，对多基地进行综合管理，保障基地运营的规范性</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.Part4 {
  min-width: 1200px !important
  ;
  padding: 140px 0 100px;
  background-color: #fff;
  .titleBlack {
    margin-bottom: 160px;
    font-weight: bold;
    font-size: 36px;
    .tipBlack {
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333;
      /* line-height: 40px; */
      margin-top: 30px;
    }
  }
  img {
    width: 100%;
  }
  .one {
    width: 25%;
    text-align: center;
    flex: 1;
  }
  .box {
    display: flex;
  }
  .text {
    font-size: 24px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    margin: 20px auto;
  }
  .stext {
    width: 80%;
    margin: 0 auto;
    font-weight: 400;
    font-size: 16px;
  }
}
</style>
