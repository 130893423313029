<template>
  <div class="Solution">
    <div class="grain" v-if="$route.query.type == '1'">
      <Part1></Part1>
      <Part2></Part2>
      <Part3></Part3>
      <Part4></Part4>
    </div>
    <div class="sale" v-if="$route.query.type == '2'">
      <Part5></Part5>
      <Part6></Part6>
      <Part7></Part7>
      <Part8></Part8>
    </div>
    <div class="gis" v-if="$route.query.type == '3'">
      <Part9></Part9>
      <Part10></Part10>
      <Part11></Part11>
      <Part12></Part12>
    </div>
    <div class="safe" v-if="$route.query.type == '4'">
      <Part13></Part13>
      <Part14></Part14>
      <Part15></Part15>
      <Part16></Part16>
    </div>
  </div>
</template>

<script>
import Part1 from '@/components/solution/grain/Part1.vue'
import Part2 from '@/components/solution/grain/Part2.vue'
import Part3 from '@/components/solution/grain/Part3.vue'
import Part4 from '@/components/solution/grain/Part4.vue'

import Part5 from '@/components/solution/sale/Part1.vue'
import Part6 from '@/components/solution/sale/Part2.vue'
import Part7 from '@/components/solution/sale/Part3.vue'
import Part8 from '@/components/solution/sale/Part4.vue'

import Part9 from '@/components/solution/gis/Part1.vue'
import Part10 from '@/components/solution/gis/Part2.vue'
import Part11 from '@/components/solution/gis/Part3.vue'
import Part12 from '@/components/solution/gis/Part4.vue'

import Part13 from '@/components/solution/safe/Part1.vue'
import Part14 from '@/components/solution/safe/Part2.vue'
import Part15 from '@/components/solution/safe/Part3.vue'
import Part16 from '@/components/solution/safe/Part4.vue'
export default {
  name: 'Solution',
  components: {
    Part1,
    Part2,
    Part3,
    Part4,
    Part5,
    Part6,
    Part7,
    Part8,
    Part9,
    Part10,
    Part11,
    Part12,
    Part13,
    Part14,
    Part15,
    Part16
  },

  mounted() {
    window.scroll(0, 0)
  }
}
</script>
