<template>
  <div class="Part2">
    <div class="w box">
      <div class="titleBlack">
        <div>服务一体化粮食管理解决方案专家</div>
        <div class="tipBlack">全渠道全场景农业解决方案，助力农业产业打造智慧农业生态</div>
      </div>
      <img src="@/assets/image/pic/pic1@2x.png" alt>
      <div>
        <div class="one">
          <p style="font-size: 28px;font-weight: 400;color: #2675FE;margin-bottom:28px">多角色精细化管理</p>
          <p
            style="font-size: 18px;font-weight: 300;color: #888888;text-align:left;font-family: Source Han Sans CN;"
          >多功能多角色一一对应，操做简单便捷，让管理更加规范</p>
        </div>
        <div class="two">
          <p style="font-size: 28px;font-weight: 400;color: #2675FE;margin-bottom:28px">数字化粮食收储流程</p>
          <p
            style="font-size: 18px;font-weight: 300;color: #888888;text-align:left"
          >从收储到结算全流程数字化，告别传统收储模式，更加简单高效</p>
        </div>
        <div class="three">
          <p style="font-size: 28px;font-weight: 400;color: #2675FE;margin-bottom:28px">交易安全有保障</p>
          <p
            style="font-size: 18px;font-weight: 300;color: #888888;text-align:left"
          >全流程数据存证（国家信息中心认可），交易安全有保障</p>
        </div>
        <div class="four">
          <p style="font-size: 28px;font-weight: 400;color: #2675FE;margin-bottom:28px">数据统计一目了然</p>
          <p
            style="font-size: 18px;font-weight: 300;color: #888888;text-align:left"
          >告别传统手工统计收集收储数据，避免出错，提高效率</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.Part2 {
  min-width: 1200px !important
  ;
  padding: 140px 0 200px;
  background-color: #fff;
  .titleBlack {
    margin-bottom: 160px;
    font-weight: bold;
    font-size: 36px;
    .tipBlack {
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333;
      /* line-height: 40px; */
      margin-top: 30px;
    }
  }
  img {
    width: 100%;
  }
  p {
    float: left;
  }
  .box {
    position: relative;
  }
  .one {
    width: 288px;
    position: absolute;
    top: 56%;
    left: 41%;
  }
  .two {
    width: 288px;
    position: absolute;
    top: 78%;
    left: 0%;
  }
  .three {
    width: 288px;
    position: absolute;
    top: 78%;
    left: 78%;
  }
  .four {
    width: 288px;
    position: absolute;
    top: 103%;
    left: 41%;
  }
}
</style>
