<template>
  <div class="Part2">
    <div class="w">
      <div class="titleBlack">
        <div>GIS地图数字农业解决方案</div>
        <div class="tipBlack">利用GIS技术、大数据相关技术, 实现农业地数据和业务数据采集</div>
      </div>
      <!-- <img src="@/assets/image/solution/gis/part2@2x.png" alt="" /> -->
      <div class="box">
        <div class="one">
          <img src alt>
          <div class="box-one">种粮一体化</div>
          <div class="box-rectangle"></div>
          <div class="box-two">面向农业经营主体，专注于服务与农产品相关的农业生态市场，可帮助实现种得好、管得好、卖得掉、品牌化。</div>
        </div>
        <div class="two">
          <img src alt>
          <div class="box-one">生产管控</div>
          <div class="box-rectangle"></div>
          <div class="box-two">面向农业经营主体和农业示范园区，专注于无人机、水泵、播种机、打药机等设备实现全自动化控制。</div>
        </div>
        <div class="three">
          <img src alt>
          <div class="box-one">农业地数据</div>
          <div class="box-rectangle"></div>
          <div class="box-two">面向农业经营主体和农业示范园区，利用GIS地图农业数据收集技术，帮助生产者开展农业地数据管理。</div>
        </div>
        <div class="four">
          <img src alt>
          <div class="box-one">业务数据</div>
          <div class="box-rectangle"></div>
          <div class="box-two">面向农业经营主体和农业示范园区，利用大数据相关技术打造区域数字农业，实现农业用地的业务数据采集。</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.Part2 {
  min-width: 1200px !important
  ;
  padding: 140px 0 100px;
  background-color: #fff;
  .titleBlack {
    margin-bottom: 160px;
    font-weight: bold;
    font-size: 36px;
    .tipBlack {
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333;
      /* line-height: 40px; */
      margin-top: 30px;
    }
  }
  img {
    width: 100%;
  }
  .box {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .one {
    width: 23.18%;
    height: 420px;
    background-color: #fff;
    background-size: 20% !important;
    background: url("../../../assets/image/partTwo/yitihua@2x.png") no-repeat;
    background-position: 50% 16%;
    box-shadow: 0 0 5px 1px rgb(224, 223, 223);
  }
  .one:hover {
    width: 23.18%;
    height: 420px;
    background-color: #2f54eb !important;
    background-size: 20% !important;
    background: url("../../../assets/image/partTwo/yitihua@2x (1).png")
      no-repeat;
    background-position: 50% 16%;
    box-shadow: 0 0 5px 1px rgb(224, 223, 223);
    .box-one {
      font-size: 24px;
      font-weight: 500;
      color: #fff;
      margin: 45px auto 13px;
    }
    .box-rectangle {
      width: 15%;
      height: 3px;
      margin: 0px auto 48px;
      background-color: #ffffff;
    }
    .box-two {
      width: 90%;
      margin: 0 auto;
      text-align: left !important;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 100;
      color: #ffffff;
    }
  }
  .two {
    width: 23.18%;
    height: 420px;
    background-color: #fff;
    background-size: 20% !important;
    background: url("../../../assets/image/partTwo/shengchan@2x (3).png")
      no-repeat;
    background-position: 50% 16%;
    box-shadow: 0 0 5px 1px rgb(224, 223, 223);
  }
  .two:hover {
    width: 23.18%;
    height: 420px;
    background-color: #2f54eb !important;
    background-size: 20% !important;
    background: url("../../../assets/image/partTwo/shengchan@2x (4).png")
      no-repeat;
    background-position: 50% 16%;
    box-shadow: 0 0 5px 1px rgb(224, 223, 223);
    .box-one {
      font-size: 24px;
      font-weight: 500;
      color: #fff;
      margin: 45px auto 13px;
    }
    .box-rectangle {
      width: 15%;
      height: 3px;
      margin: 0px auto 48px;
      background-color: #ffffff;
    }
    .box-two {
      width: 90%;
      margin: 0 auto;
      text-align: left !important;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 100;
      color: #ffffff;
    }
  }
  .three {
    width: 23.18%;
    height: 420px;
    background-color: #fff;
    background-size: 20% !important;
    background: url("../../../assets/image/partTwo/nongye@2x (1).png") no-repeat;
    background-position: 50% 16%;
    box-shadow: 0 0 5px 1px rgb(224, 223, 223);
  }
  .three:hover {
    width: 23.18%;
    height: 420px;
    background-color: #2f54eb !important;
    background-size: 20% !important;
    background: url("../../../assets/image/partTwo/nongye@2x.png") no-repeat;
    background-position: 50% 16%;
    box-shadow: 0 0 5px 1px rgb(224, 223, 223);
    .box-one {
      font-size: 24px;
      font-weight: 500;
      color: #fff;
      margin: 45px auto 13px;
    }
    .box-rectangle {
      width: 15%;
      height: 3px;
      margin: 0px auto 48px;
      background-color: #ffffff;
    }
    .box-two {
      width: 90%;
      margin: 0 auto;
      text-align: left !important;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 100;
      color: #ffffff;
    }
  }
  .four {
    width: 23.18%;
    height: 420px;
    background-color: #fff;
    background-size: 20% !important;
    background: url("../../../assets/image/partTwo/yewu@2x (1).png") no-repeat;
    background-position: 50% 16%;
    box-shadow: 0 0 5px 1px rgb(224, 223, 223);
  }
  .four:hover {
    width: 23.18%;
    height: 420px;
    background-color: #2f54eb !important;
    background-size: 20% !important;
    background: url("../../../assets/image/partTwo/yewu@2x.png") no-repeat;
    background-position: 50% 16%;
    box-shadow: 0 0 5px 1px rgb(224, 223, 223);
    .box-one {
      font-size: 24px;
      font-weight: 500;
      color: #fff;
      margin: 45px auto 13px;
    }
    .box-rectangle {
      width: 15%;
      height: 3px;
      margin: 0px auto 48px;
      background-color: #ffffff;
    }
    .box-two {
      width: 90%;
      margin: 0 auto;
      text-align: left !important;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 100;
      color: #ffffff;
    }
  }
  .box-one {
    font-size: 24px;
    font-weight: 500;
    color: #333333;
    margin: 45px auto 13px;
  }
  img {
    width: 18%;
    margin: 60px auto 51px;
  }
  .box-rectangle {
    width: 15%;
    height: 3px;
    margin: 0px auto 48px;
    background-color: #2f54eb;
  }
  .box-two {
    width: 90%;
    margin: 0 auto;
    text-align: left !important;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 200;
    color: #000;
  }
}
</style>
