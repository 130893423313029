<template>
  <div class="Part4">
    <div class="w">
      <div class="titleBlack">
        <div>安防管理之“微智能，大智慧”</div>
        <div class="tipBlack">
          智能编码 智能侦测 智能控制 大容量接入 多屏显示
        </div>
      </div>
      <!-- <img src="@/assets/image/solution/safe/part4@2x.png" alt="" /> -->
      <div class="box">
        <div class="one">
          <img src="@/assets/image/pic/1@2x.png" alt="">
          <p style="color:#fff">视频监控系统</p>
          <ul>
            <li>• 磁盘存储阵列</li>
            <li>• 视频综合平台</li>
            <li>• 高密度存储</li>
            <li>• 高密度解码</li>
          </ul>
        </div>
        <div class="one">
           <img src="@/assets/image/pic/bode@@2x (1).png" alt="">
           <p>微智能•轻系统</p>
          <ul>
            <li>• 大容量接入</li>
            <li>• 视频综合管理</li>
            <li>• 支持第三方集成</li>
            <li>• 监控中心一体</li>
          </ul>
        </div>
         <div class="one">
          <img src="@/assets/image/pic/bode@@2x.png" alt="">
          <p>智能侦测系统</p>
          <ul>
            <li>• 高请球/枪机</li>
            <li>• 数字高清机芯</li>
            <li>• 高稳定/性价比</li>
            <li>• 图片音频侦测</li>
          </ul>
        </div>
        <div class="one">
           <img src="@/assets/image/pic/bode@@2x (1).png" alt="">
           <p>安防融合系统</p>
          <ul>
            <li>• 动态调整编码</li>
            <li>• 分散存储、集中管理</li>
            <li>• 数字视频矩阵</li>
            <li>• 全实时输出的DVR</li>
          </ul>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.Part4 {
  min-width: 1200px !important
  ;
  padding: 140px 0 100px;
  background-color: #fff;
   .titleBlack {
    margin-bottom: 160px;
    font-weight: bold;
    font-size: 36px;
    .tipBlack {
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333;
      /* line-height: 40px; */
      margin-top: 30px;
     
    }
  }
  img {
    width: 100%;
  }
   .one{
    position: relative;
    width: 25%;  
    height: 361px;
    background: #FFFFFF;
    border: 1px solid #EDEDED;
    border-radius: 8px 0px 0px 0px;
  }
  .box{
    display: flex;
    flex: 1
  }
  p{
    height: 24px;
    font-size: 24px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    line-height: 40px;   
    position: absolute;
    top:2%;
    left:21%;
  }
  ul{
   margin-left: 15%!important;
   padding:0;
   margin:0;
   text-align: left;
  }
 li{
font-size: 18px;
font-family: Source Han Sans CN;
font-weight: 300;
color: #666666;
padding:22px;
 }
}
</style>
