<template>
  <div class="Part4">
    <div class="w">
      <div class="titleBlack">
        <div>专业决定品质 销售管理模式优势</div>
        <div class="tipBlack">团队优势/丰富经验/专业服务/匠人精神</div>
      </div>
      <!-- <img src="@/assets/image/solution/sale/part4@2x.png" alt="" /> -->
      <!-- <div class="box">
        <div class="top">
          <div class="top-left box-box">
            <div class="box-top">
              <img src="@/assets/image/partTwo/team@2x.png"  alt="" style="width:100px;height:100px">
            <div>
              <div style="font-size: 24px;margin-bottom:25px">优秀团队</div>
              <div style="font-size: 16px;font-family: Source Han Sans CN;font-weight: 300;width:50%">专业开发团队，产品经理深度客户需求分析，UI设计交互支持，强大的前后端技术开发，严谨的程序性能测试，专业的人员配置，成家高品质的销售管理模式开发服务。</div>
            </div>
            </div>
            
          </div>
          <div class="top-right box-box"></div>
        </div>
         <div class="bottom">
          <div class="bottom-left box-box"></div>
          <div class="bottom-right box-box"></div>
        </div>
      </div>-->
      <div class="box">
        <div class="top">
          <div class="top-left">
            <img
              src="@/assets/image/pic/592@2x.png"
              alt
              style="width: 500px; height: 240px"
            />
            <img
              src="@/assets/image/partTwo/team@2x.png"
              alt
              style="
                width: 100px;
                height: 100px;
                position: absolute;
                top: 27%;
                left: 2%;
              "
            />
            <p
              style="
                font-size: 24px;
                font-weight: 500;
                position: absolute;
                top: 25%;
                left: 25%;
              "
            >
              优秀团队
            </p>
            <p
              style="
                font-size: 16px;
                font-weight: 300;
                position: absolute;
                top: 42%;
                left: 24%;
                width: 70%;
                text-align: left !important;
              "
            >
              专业开发团队，产品经理深度客户需求分析，UI设计交互支持，强大的前后端技术开发，严谨的程序性能测试，专业的人员配置，成家高品质的销售管理模式开发服务。
            </p>
          </div>
          <div class="top-left">
            <img
              src="@/assets/image/pic/592@2x.png"
              alt
              style="width: 500px; height: 240px"
            />
            <img
              src="@/assets/image/pic/experience@2x (1).png"
              alt
              style="
                width: 100px;
                height: 100px;
                position: absolute;
                top: 27%;
                left: 2%;
              "
            />
            <p
              style="
                font-size: 24px;
                font-weight: 500;
                position: absolute;
                top: 25%;
                left: 25%;
              "
            >
              成熟经验
            </p>
            <p
              style="
                font-size: 16px;
                font-weight: 300;
                position: absolute;
                top: 42%;
                left: 24%;
                width: 70%;
                text-align: left !important;
              "
            >
              以龙头企业为主导进行场景化应用，为企业打造服务业务发展的开发解决方案服务。我们倡导让客户感知产品的应用体验价值，而不仅仅是某一产品的功能。
            </p>
          </div>
        </div>
        <div class="bottom">
          <div class="top-left">
            <img
              src="@/assets/image/pic/592@2x.png"
              alt
              style="width: 500px; height: 240px"
            />
            <img
              src="@/assets/image/pic/system@2x (4).png"
              alt
              style="
                width: 100px;
                height: 100px;
                position: absolute;
                top: 27%;
                left: 2%;
              "
            />
            <p
              style="
                font-size: 24px;
                font-weight: 500;
                position: absolute;
                top: 25%;
                left: 25%;
              "
            >
              系统流程
            </p>
            <p
              style="
                font-size: 16px;
                font-weight: 300;
                position: absolute;
                top: 42%;
                left: 24%;
                width: 70%;
                text-align: left !important;
              "
            >
              科学合理的流程，实际业务场景考察、用户沟通、原型设计、软件测试、运维维护，独有的质量监督层层把关，确保过硬的销售管理产品的质量，更好的为企业提供专业的服务。
            </p>
          </div>
          <div class="top-left">
            <img
              src="@/assets/image/pic/592@2x.png"
              alt
              style="width: 500px; height: 240px"
            />
            <img
              src="@/assets/image/partTwo/team@2x.png"
              alt
              style="
                width: 100px;
                height: 100px;
                position: absolute;
                top: 27%;
                left: 2%;
              "
            />
            <p
              style="
                font-size: 24px;
                font-weight: 500;
                position: absolute;
                top: 25%;
                left: 25%;
              "
            >
              专业服务
            </p>
            <p
              style="
                font-size: 16px;
                font-weight: 300;
                position: absolute;
                top: 42%;
                left: 24%;
                width: 70%;
                text-align: left !important;
              "
            >
              严格遵循实际业务场景运营模式，规范管理，完善的团队全心全意服务，提供专业的各项问题服务解决方案。
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.Part4 {
  min-width: 1200px !important
  ;
  padding: 140px 0 100px;
  background-color: #eef0fa;
  .titleBlack {
    margin-bottom: 160px;
    font-weight: bold;
    font-size: 36px;
    .tipBlack {
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333;
      /* line-height: 40px; */
      margin-top: 30px;
    }
  }
  img {
    width: 100%;
  }
  .top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 60px;
  }
  .bottom {
    display: flex;
    justify-content: space-between;
  }
  .box-box {
    width: 47.5%;
    height: 240px;
    background-color: #fff;
  }
  .box-top {
    display: flex;
    justify-content: space-between;
    margin-top: 60px;
  }
  .top-left {
    position: relative;
  }
}
</style>
