<template>
  <div class="Part3">
    <div class="w">
      <div class="titleBlack">
        <div>集成化的产品运营支撑系统</div>
        <div class="tipBlack">除了技术产品，一村还搭建了集产品、运营与一体的数字化监控平台</div>
      </div>
      <img src="@/assets/image/solution/safe/part3@2x.png" alt>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.Part3 {
  min-width: 1200px !important
  ;
  padding: 140px 0 100px;
  background-color: #fff;
  .titleBlack {
    margin-bottom: 160px;
    font-weight: bold;
    font-size: 36px;
    .tipBlack {
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333;
      /* line-height: 40px; */
      margin-top: 30px;
    }
  }
  img {
    width: 100%;
  }
  .one {
    position: relative;
    width: 25%;
    height: 361px;
    background: #ffffff;
    border: 1px solid #ededed;
    border-radius: 8px 0px 0px 0px;
  }
  .box {
    display: flex;
    flex: 1;
  }
  p {
    height: 24px;
    font-size: 24px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #ffffff;
    line-height: 40px;
    position: absolute;
    top: 2%;
    left: 21%;
  }
  li {
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 300;
    color: #666666;
    padding: 22px;
  }
}
</style>
