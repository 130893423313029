<template>
  <div class="Part2">
    <div class="w">
      <!-- <div class="titleBlack">
        <div>强大且可靠的app线上商城</div>
        <div class="tipBlack">
          高效率的销售管理模式 专业团队倾心打造模块化服务
        </div>
      </div> -->
      <!-- <img src="@/assets/image/solution/safe/part2@2x.png" alt="" /> -->
      <div class="box">
        <div class="one">
          <img src="@/assets/image/pic/593@2x.png" alt="" />
          <div class="one-one">
            <div class="one-on">
              <img
                src="@/assets/image/pic/reliable@2x (1).png"
                alt=""
                style="width: 80px; height: 80px"
              />
              <div class="one-two">
                <p
                  style="
                    font-size: 24px;
                    font-family: Source Han Sans CN;
                    font-weight: 500;
                    text-align: left;
                  "
                >
                  高可靠
                </p>
                <p
                  style="
                    font-size: 16px;
                    font-weight: 200;
                    font-family: Source Han Sans CN;
                    color: #666666;
                  "
                >
                  可用信>=95%，7*24h服务保障
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="two">
          <img src="@/assets/image/pic/593@2x.png" alt="" />
          <div class="one-one">
            <div class="one-on">
              <img
                src="@/assets/image/pic/unify@2x (1).png"
                alt=""
                style="width: 80px; height: 80px"
              />
              <div class="one-two">
                <p
                  style="
                    font-size: 24px;
                    font-family: Source Han Sans CN;
                    font-weight: 500;
                    text-align: left;
                  "
                >
                  一体化
                </p>
                <p
                  style="
                    font-size: 16px;
                    font-weight: 200;
                    font-family: Source Han Sans CN;
                    color: #666666;
                  "
                >
                  一个账户开通所有技术产品及服务
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="three">
          <img src="@/assets/image/pic/593@2x.png" alt="" />
          <div class="one-one">
            <div class="one-on">
              <img
                src="@/assets/image/pic/system@2x (3).png"
                alt=""
                style="width: 80px; height: 80px"
              />
              <div class="one-two">
                <p
                  style="
                    font-size: 24px;
                    font-family: Source Han Sans CN;
                    font-weight: 500;
                    text-align: left;
                  "
                >
                  轻系统
                </p>
                <p
                  style="
                    font-size: 16px;
                    font-weight: 200;
                    font-family: Source Han Sans CN;
                    color: #666666;
                  "
                >
                  智能综合监控，功能丰富使用便利
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.Part2 {
  min-width: 1200px !important
  ;
  padding: 140px 0 100px;
  background-color: #f9f9fe;
  .titleBlack {
    margin-bottom: 160px;
    .tipBlack {
      font-size: 24px;
      font-family: Source Han Sans CN;
      color: #33333388;
      /* line-height: 40px; */
      margin-top: 30px;
    }
  }
  img {
    width: 410px;
    height: 162px;
  }
  .box {
    display: flex;
    flex: 1;
  }
  .one {
    position: relative;
  }
  .two {
    position: relative;
  }
  .three {
    position: relative;
  }
  p {
    font-size: 24px;
    font-weight: 500;
    font-family: Source Han Sans CN;
    padding-top: 10px;
    padding-left: 15px;
  }
  .one-on {
    display: flex;
    justify-content: space-around;
  }
  .one-one {
    position: absolute;
    top: 22%;
    left: 8%;
  }
}
</style>
