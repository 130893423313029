<template>
  <div class="Part3">
    <div class="w">
      <div class="titleBlack">
        <div>农业保险解决方案</div>
        <div class="tipBlack">经营主体可为农作物进行投保, 减少自然灾害对农业生产的影响</div>
      </div>
      <!-- <img src="@/assets/image/solution/gis/part3@2x.png" alt="" /> -->
      <div class="box">
        <div class="left">
          <div class="one">
            <div style="width:30%;margin-top:7%;font-size: 24px;font-weight: 500;">投保服务</div>
            <div
              style="width:70%;margin-top:6%;text-align: left!important"
            >农业保险为农业发展的自然、经济、社会三大风险提供了有效的保障功能，为保障我国国民经济持续快速健康发展发挥其应有的作用。</div>
          </div>
          <div class="one">
            <div style="width:30%;margin-top:7%;font-size: 24px;font-weight: 500;">农作物投保</div>
            <div
              style="width:70%;margin-top:6%;text-align: left!important"
            >一村与保险公司达成农业保险业务合作，在实际业务真实存在的前天下，允许经营主体为农作物进行投保，保障农业生产的正常进行。</div>
          </div>
          <div class="one">
            <div style="width:30%;margin-top:7%;font-size: 24px;font-weight: 500;">农作物理赔</div>
            <div
              style="width:70%;margin-top:6%;text-align: left!important"
            >在经营主体为农作物投保生效后，如农作物在生产过程中出险，达到需要保险理赔的情况，保险公司及时提供保险理赔服务。</div>
          </div>
        </div>
        <div class="right">
          <img src="@/assets/image/pic/cooperate@2x (1).png" alt>
          <div class="two">
            <div class="two-one">农业保险对接</div>
            <div
              class="two-two"
            >基于GIS地图系统所提供的农业地相关信息，经营主体为农业地购买保险，保险公司提供可靠的保险服务，稳定农民收入，促进农业和农村经济的发展。</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.Part3 {
  min-width: 1200px !important
  ;
  padding: 140px 0 100px;
  background-color: #fff;
  .titleBlack {
    margin-bottom: 160px;
    font-weight: bold;
    font-size: 36px;
    .tipBlack {
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333;
      /* line-height: 40px; */
      margin-top: 30px;
    }
  }
  img {
    width: 100%;
  }
  .box {
    display: flex;
    justify-content: space-between;
  }
  .left {
    width: 64%;
    height: 480px;
    .one {
      width: 100%;
      height: 126px;
      border: 1px solid #eaf2ff;
      margin-bottom: 32px;
      display: flex;
      justify-content: space-between;
    }
  }
  .right {
    width: 30%;
    height: 480px;
    position: relative;
    .two {
      position: absolute;
      top: 27%;
      left: 0%;
      .two-one {
        font-size: 24px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #ffffff;
      }
      .two-two {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 300;
        color: #ffffff;
        width: 80%;
        margin: 38px auto;
      }
    }
  }
}
</style>
