<template>
  <div class="Part3">
    <div class="w">
      <div class="titleBlack">
        <div>助力传统粮食管理数字化升级</div>
        <div class="tipBlack">数字化管理的实现大大提高了粮食管理的效率，不受时间、空间等限制更灵活</div>
      </div>
      <img src="@/assets/image/solution/grain/part3@2x.png" alt>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.Part3 {
  min-width: 1200px !important
  ;
  padding: 140px 0 100px;
  background-color: #eef0fa;
  .titleBlack {
    margin-bottom: 160px;
    font-weight: bold;
    font-size: 36px;
    .tipBlack {
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333;
      /* line-height: 40px; */
      margin-top: 30px;
    }
  }
  img {
    width: 100%;
  }
}
</style>
