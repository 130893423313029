<template>
  <div class="Part4">
    <div class="w">
      <div class="titleBlack">
        <div>GIS地图与保险融合</div>
        <div class="tipBlack">利用GIS地图技术与农业保险相结合，稳定农民收入，促进经济发展</div>
      </div>
      <img src="@/assets/image/solution/gis/part4@2x.png" alt>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.Part4 {
  min-width: 1200px !important
  ;
  padding: 140px 0 100px;
  background-color: #fff;
  .titleBlack {
    margin-bottom: 160px;
    font-weight: bold;
    font-size: 36px;
    .tipBlack {
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333;
      /* line-height: 40px; */
      margin-top: 30px;
    }
  }
  img {
    width: 100%;
  }
}
</style>
