<template>
  <div class="Part2">
    <div class="w">
      <div class="titleBlack">
        <div>强大且可靠的app线上商城</div>
        <div class="tipBlack">
          高效率的销售管理模式 专业团队倾心打造模块化服务
        </div>
      </div>
      <div class="box">
        <div class="one">
          <img src="@/assets/image/pic/593@2x.png" alt />
          <div class="one-one">
            <img
              src="@/assets/image/pic/icon1@2x.png"
              alt
              style="width: 136px; height: 136px"
            />
            <p>多角色商户入驻</p>
          </div>
        </div>
        <div class="two">
          <img src="@/assets/image/pic/593@2x.png" alt />
          <div class="one-two">
            <img
              src="@/assets/image/pic/icon2@2x.png"
              alt
              style="width: 136px; height: 136px"
            />
            <p>银行直连资金保障</p>
          </div>
        </div>
        <div class="three">
          <img src="@/assets/image/pic/593@2x.png" alt />
          <div class="one-three">
            <img
              src="@/assets/image/pic/icon3@2x.png"
              alt
              style="width: 136px; height: 136px"
            />
            <p>国家信息中心数据保全</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.Part2 {
  min-width: 1200px !important
  ;
  padding: 140px 0 100px;
  background-color: #fff;
  .titleBlack {
    margin-bottom: 160px;
    font-weight: bold;
    font-size: 36px;
    .tipBlack {
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333;
      /* line-height: 40px; */
      margin-top: 30px;
    }
  }
  img {
    width: 100%;
  }
  .box {
    display: flex;
    justify-content: space-between;
  }
  .one {
    position: relative;
  }
  .two {
    position: relative;
  }
  .three {
    position: relative;
  }
  p {
    font-size: 24px;
    font-weight: 500;
    font-family: Source Han Sans CN;
    padding-top: 35px;
  }
  .one-one {
    position: absolute;
    top: 16%;
    left: 26%;
  }
  .one-two {
    position: absolute;
    top: 16%;
    left: 23%;
  }
  .one-three {
    position: absolute;
    top: 16%;
    left: 16%;
  }
}
</style>
