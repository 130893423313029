<template>
  <div class="Part1">
    <!-- <div class="placeholder"></div> -->
    <!-- <div class="w">
      <div class="left">
        <span class="title">销售管理解决方案</span>
        <span class="tip"
          >提高多品类线上电商解决方案助力传统企业进入 数字化转型新时代</span
        >
      </div>
      <img src="@/assets/image/solution/safe/part1@2x.png" alt="" />
    </div>-->
    <div class="one">
      <img src="@/assets/image/pic/pic@2x (3).png" alt>
      <div class="two">
        <p style="font-size: 36px;font-weight: 500;color: #FFFFFF;margin-bottom:39px">销售管理解决方案</p>
        <p
          style="font-size: 18px;font-weight: 300;color: #FFFFFF;text-align:left"
        >提高多品类线上电商解决方案助力传统企业进入 数字化转型新时代</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.Part1 {
  min-width: 1200px !important
  ;
  // padding-top: 20px;
  // box-sizing: border-box;
  // height: 560px;
  // background-color: #6b73f1;
  background-image: linear-gradient(to bottom, #5f7cf5, #746ded);
  .placeholder {
    height: 80px;
  }
  .w {
    height: 480px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      text-align-last: left;
      .title {
        font-size: 36px;
        font-family: SourceHanSansCN;
        font-weight: 500;
        color: #ffffff;
        line-height: 40px;
        margin-bottom: 40px;
      }
      .tip {
        width: 500px;
        font-size: 18px;
        font-family: SourceHanSansCN;
        font-weight: 300;
        color: #ffffff;
        line-height: 40px;
      }
    }
    img {
      height: 400px;
      // margin-left: 90px;
    }
  }
  img {
    width: 100%;
  }
  .one {
    position: relative;
  }
  .two {
    width: 557px;
    position: absolute;
    top: 50%;
    left: 16%;
  }
  p {
    float: left;
  }
}
</style>
