<template>
  <div class="Part3">
    <div class="w">
      <div class="titleBlack">
        <div>销售管理模式特征</div>
        <div class="tipBlack">成熟的销售管理模式 构建完善的专业的销售管理模式</div>
      </div>
      <!-- <img src="@/assets/image/solution/sale/part3@2x.png" alt="" /> -->
      <div class="box">
        <div class="left">
          <img src="@/assets/image/partTwo/icon@2x.png" alt class="iconclass">
          <div style="font-size: 24px; font-weight: 500;margin-bottom:25px">多角色商户入驻</div>
          <div
            style="font-size: 16px; font-weight: 400;color:#000"
          >轻松玩转开店卖货不受限，个人/企业/多角色，完善的销售管理模式满足不同客户身份的需求，让您卖货不受限。</div>
        </div>
        <div class="right">
          <img src="@/assets/image/partTwo/right1@2x.png" alt class="rightclass">
        </div>
      </div>
      <div class="box">
        <div class="right">
          <img src="@/assets/image/partTwo/left1@2x.png" alt class="rightclass">
        </div>
        <div class="left">
          <img src="@/assets/image/partTwo/icon@2x (1).png" alt class="iconclass">
          <div style="font-size: 24px; font-weight: 500;margin-bottom:25px">银行直连资金有保障</div>
          <div
            style="font-size: 16px; font-weight: 400;color:#000"
          >以客户为核心，为保障销售模式下业务的稳步正常进行，提供了多达5种支付方式的组合，银行官方资金清算，安全可靠。</div>
        </div>
      </div>
      <div class="box">
        <div class="left">
          <img src="@/assets/image/partTwo/icon@2x (2).png" alt class="iconclass">
          <div style="font-size: 24px; font-weight: 500;margin-bottom:25px">CRM用户管理体系</div>
          <div
            style="font-size: 16px; font-weight: 400;color:#000"
          >精细化运营提高复购会员全周期管理，精细化运营与精准营销方式增强客户粘性带来高复购行为。</div>
        </div>
        <div class="right">
          <img src="@/assets/image/partTwo/right2@2x.png" alt class="rightclass">
        </div>
      </div>
      <div class="box">
        <div class="right">
          <img src="@/assets/image/partTwo/left2@2x.png" alt class="rightclass">
        </div>
        <div class="left">
          <img src="@/assets/image/partTwo/icon@2x (3).png" alt class="iconclass">
          <div style="font-size: 24px; font-weight: 500;margin-bottom:25px">丰富的营销运营工具</div>
          <div
            style="font-size: 16px; font-weight: 400;color:#000"
          >拼团、砍价、优惠券等多种营销插件组合营销刺激客户产生购买行为，带动商家业绩高效增长，助力您获客营销。</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.Part3 {
  min-width: 1200px !important
  ;
  padding: 140px 0 100px;
  background-color: #fff;
  .titleBlack {
    margin-bottom: 160px;
    font-weight: bold;
    font-size: 36px;
    .tipBlack {
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333;
      /* line-height: 40px; */
      margin-top: 30px;
    }
  }
  img {
    width: 100%;
  }
}
.box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 150px;
}
.left {
  width: 44%;
  text-align: left;
}
.iconclass {
  width: 88px !important;
  height: 88px;
}
.rightclass {
  width: 436px !important;
  height: 326px;
}
</style>
